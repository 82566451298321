import(/* webpackMode: "eager" */ "/opt/build/repo/app/styles/app.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/utils/client-config.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/@next+third-parties@15.2.4_next@15.2.4_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@_gx4mlxd6q3ryhm7xmrjjlrmuxq/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/@next+third-parties@15.2.4_next@15.2.4_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@_gx4mlxd6q3ryhm7xmrjjlrmuxq/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/@next+third-parties@15.2.4_next@15.2.4_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@_gx4mlxd6q3ryhm7xmrjjlrmuxq/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@19.1.0_react@19.1.0__react@19.1.0_sass@1.86.2/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@19.1.0_react@19.1.0__react@19.1.0_sass@1.86.2/node_modules/next/font/local/target.css?{\"path\":\"app/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/noto_sans_thai_regular.woff2\",\"display\":\"swap\",\"style\":\"normal\",\"variable\":\"--font-noto\"}],\"variableName\":\"NotoSansThaiRegular\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@19.1.0_react@19.1.0__react@19.1.0_sass@1.86.2/node_modules/next/font/local/target.css?{\"path\":\"app/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/noto_sans_thai_bold.woff2\",\"display\":\"swap\",\"style\":\"normal\",\"variable\":\"--font-noto-bold\"}],\"variableName\":\"NotoSansThaiBold\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@19.1.0_react@19.1.0__react@19.1.0_sass@1.86.2/node_modules/next/font/local/target.css?{\"path\":\"app/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/db_eurothai_x_demi_ext_v3.2-webfont.woff2\",\"display\":\"swap\",\"style\":\"normal\",\"variable\":\"--font-display\"}],\"variableName\":\"EurothaiDemiExt\"}");
